<template>
  <KeyValue 
    :title="FIELDS.NAME" 
    placeholder="Company Name"
    :defaultValue="company.name()" 
    :disabled="isSaving" 
    v-on:value="setValue" />
</template>

<script>
import { mapGetters } from 'vuex'
import Company from '@/domain/model/company/Company.js';
import KeyValue from '@/components/input/KeyValue.vue';
import Catelog from '@/domain/session/CanadianEnglish.js';

export default  {
  name: 'company-name-edit',
  props: {
    isSaving: { type: Boolean, default: false },
    companyData: { type: Object, default: () => {} },
  },
  components: {
    KeyValue,
  },
  data() {
    return {
      catelogKeys: Catelog.KEYS,
      FIELDS: Company.FIELDS,
      
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    company: function() {
      if (this.companyData) {
        return new Company(this.domain, this.companyData);
      }
      return new Company(this.domain);
    },
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    setValue: function (kvp) {
      this.$emit('setValue', {'key': kvp.key, 'value': kvp.value})
    },
  },
}
</script>