<template>

  <Box cmpt="title">
    <TitleRow :align="align" cmpt="title" :subtitle="subtitle">
      <slot />
    </TitleRow>
  </Box>

</template>

<script>

import { mapGetters } from 'vuex'

import Box from "@/portals/shared/library/box/Box.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

export default {
  name: 'portals-shared-library-title-title',
  components: {
    Box,
    TitleRow,
  },
  props: {
    align: { type: String, default: "center" },
    subtitle: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    alignment: function() {
      if (this.align == "left") {
        return "rc-text-left";
      }
      if (this.align == "right") {
        return "rc-text-right";
      }
      return "rc-text-centre";
    }
  },
}
</script>